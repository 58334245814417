import { Row, Col } from "react-bootstrap";
import React, { useState, useEffect } from 'react';




export default function Catalog() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const disableContextMenu = (event) => {
      event.preventDefault();
    }

    document.addEventListener('contextmenu', disableContextMenu);

    return () => {
      document.removeEventListener('contextmenu', disableContextMenu);
    }
  }, []);

  return (
    <div>
      {isMobile ? (
        <MobileView />
      ) : (
        <DesktopView />
      )}
    </div>


  );
}


function MobileView() {
  return (
    <>
      <Row className="padded-top-bot centered carouselposition">
        <Col md={11} className="centeredcol">
          <h3> All Types and Colors of Leather </h3>
          <p> From Exotic to Veg Tan </p>
          <img src="images/leathersample.jpg" alt="Progression" />
          <br></br>
        </Col>
      </Row>

      <Row className="padded-top-bot centered carouselposition">
        <Col md={11} className="centeredcol">
          <h3> Thread Customization </h3>
          <p> Choose your colors with the best - Ritza Tiger Thread </p>
          <br></br>
          <img src="images/ritzathreads.jpg" alt="Progression" />
          <br></br>
        </Col>
      </Row>
      <Row className="padded-top-bot carouselposition">
        <h1 className="maxheight centeredcol">Available Now:</h1>
        <p> Please Contact for Inquires</p>
        <h1></h1>
        <Col md={6} className="centeredcol">
          <img src="images/Catalog-15.jpg" alt="AN-1" />
          <h3> Front-Side</h3> 
          <br></br>
        </Col>
        <Col md={6} className="centeredcol">
          <img src="images/Catalog-16.jpg" alt="AN-2" />
          <h3> Back-Side</h3> 
          <br></br>
        </Col>
      </Row>
      <Row className="padded-top-bot carouselposition">
        <h1 className="maxheight centeredcol">Waterproof Daily Bag</h1>
        <h1></h1>
        <h1></h1>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-18.jpg" alt="Waterproof Daily Bag 1" />
          <br></br>
        </Col>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-17.jpg" alt="Waterproof Daily Bag 2" />
          <br></br>
        </Col>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-19.jpg" alt="Waterproof Daily Bag 3" />
          <br></br>
        </Col>
      </Row>
      <Row className="padded-top-bot carouselposition">
        <h1 className="maxheight centeredcol">Front Pocket Wallet</h1>
        <h1></h1>
        <h1></h1>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-08.jpg" alt="Front Pocket Wallet 1" />
          <br></br>
        </Col>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-09.jpg" alt="Front Pocket Wallet 2" />
          <br></br>
        </Col>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-10.jpg" alt="Front Pocket Wallet 3" />
          <br></br>
        </Col>
      </Row>

      <Row className="padded-top-bot carouselposition">
        <h1 className="maxheight centeredcol">Minimalist Card Wallet</h1>
        <h1></h1>
        <h1></h1>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-14.jpg" alt="Minamalist Wallet 1" />
          <br></br>
        </Col>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-13.jpg" alt="Minamalist Wallet 2" />
          <br></br>
        </Col>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-11.jpg" alt="Minamalist Wallet 3" />
          <br></br>
        </Col>
      </Row>
      <Row className="padded-top-bot carouselposition">
        <h1 className="maxheight centeredcol">Field Notes Journal Cover</h1>
        <h1></h1>
        <h1></h1>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-07.jpg" alt="Field Notes Journal Cover 1" />
          <br></br>
        </Col>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-06.jpg" alt="Field Notes Journal Cover 2" />
          <br></br>
        </Col>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-02.jpg" alt="Field Notes Journal Cover 3" />
          <br></br>
        </Col>
      </Row>
    </>
  );
}

function DesktopView() {
  return (
    <>
      <Row className="padded-top-bot centered carouselposition">
        <Col md={4} className="centeredcol">
          <h3> All Types and Colors of Leather </h3>
          <p> From Exotic to Veg Tan! </p>
          <br></br>
          <img src="images/leathersample.jpg" alt="Progression" />
          <br></br>
        </Col>
        <Col md={4} className="centeredcol">
          <h3> Choose your Thread  </h3>
          <p> Your favorite colors with the best - Ritza Tiger Thread </p>
          <br></br>
          <img src="images/ritzathreads.jpg" alt="Progression" />
          <br></br>
        </Col>
        <Col md={4} className="centeredcol">
          <h3> Customization and Personalization </h3>
          <p> </p>
          <br></br>
          <img src="images/Catalog-01.jpg" alt="Progression" />
          <br></br>
        </Col>
      </Row>
      <Row className="padded-top-bot carouselposition">
        <h1 className="maxheight centeredcol">Available Now:</h1>
        <p> Please Contact for Inquires</p>
        <h1></h1>
        <Col md={6} className="centeredcol">
          <img src="images/Catalog-15.jpg" alt="AN-1" />
          <h3> Front-Side</h3> 
          <br></br>
        </Col>
        <Col md={6} className="centeredcol">
          <img src="images/Catalog-16.jpg" alt="AN-2" />
          <h3> Back-Side</h3> 
          <br></br>
        </Col>
      </Row>
      <Row className="padded-top-bot carouselposition">
        <h1 className="maxheight centeredcol">Waterproof Daily Bag</h1>
        <h1></h1>
        <h1></h1>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-18.jpg" alt="Waterproof Daily Bag 1" />
          <br></br>
        </Col>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-17.jpg" alt="Waterproof Daily Bag 2" />
          <br></br>
        </Col>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-19.jpg" alt="Waterproof Daily Bag 3" />
          <br></br>
        </Col>
      </Row>
      <Row className="padded-top-bot carouselposition">
        <h1 className="maxheight centeredcol">Front Pocket Wallet</h1>
        <h1></h1>
        <h1></h1>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-08.jpg" alt="Front Pocket Wallet 1" />
          <br></br>
        </Col>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-09.jpg" alt="Front Pocket Wallet 2" />
          <br></br>
        </Col>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-10.jpg" alt="Front Pocket Wallet 3" />
          <br></br>
        </Col>
      </Row>

      <Row className="padded-top-bot carouselposition">
        <h1 className="maxheight centeredcol">Minimalist Card Wallet</h1>
        <h1></h1>
        <h1></h1>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-14.jpg" alt="Minamalist Wallet 1" />
          <br></br>
        </Col>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-13.jpg" alt="Minamalist Wallet 2" />
          <br></br>
        </Col>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-11.jpg" alt="Minamalist Wallet 3" />
          <br></br>
        </Col>
      </Row>
      <Row className="padded-top-bot carouselposition">
        <h1 className="maxheight centeredcol">Field Notes Journal Cover</h1>
        <h1></h1>
        <h1></h1>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-07.jpg" alt="Field Notes Journal Cover 1" />
          <br></br>
        </Col>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-06.jpg" alt="Field Notes Journal Cover 2" />
          <br></br>
        </Col>
        <Col md={4} className="centeredcol">
          <img src="images/Catalog-02.jpg" alt="Field Notes Journal Cover 3" />
          <br></br>
        </Col>
      </Row>
    </>
  );
}



