import React from 'react'
import NewArrivalGal from '../Components/NewArrivals';
import Main from "../Components/Main";
import Footer from "../Components/Footer";
import "../App.css"

export default function NewArrivals() {
  return (
    <div>
      <NewArrivalGal/>
    </div>
  )
}