import { Container, Row, Col, Carousel } from "react-bootstrap";
import React, { useState, useEffect } from 'react';

const Main = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const disableContextMenu = (event) => {
          event.preventDefault();
        }
    
        document.addEventListener('contextmenu', disableContextMenu);
    
        return () => {
          document.removeEventListener('contextmenu', disableContextMenu);
        }
      }, []);

    return (
        <div>
            {isMobile ? (
                <MobileView />
            ) : (
                <DesktopView />
            )}
        </div>


    );
};
export default Main;


function MobileView() {
    return (
        <>
            <Row className="padded-top-bot centered ">
                {/* <Col md={4} className="centeredcol carouselposition maxheight">
            <h1 className="maxheight centeredcol">Brief History of Leather</h1>
                <p>
                    <br></br>
                    The history of leather spans millennia, beginning in prehistoric times when early humans recognized the resilience and adaptability of animal hides. These hides were utilized for essential needs such as clothing, shelter, and tools. As civilizations like Egypt and Rome emerged, they refined leatherworking techniques, employing it for garments, footwear, and even ceremonial artifacts. In Rome, leather became synonymous with luxury, adorning the elite as a symbol of status and prestige.
                </p>
                <p>
                    During the Middle Ages, skilled leatherworker guilds surfaced, leading to advancements in tanning processes and the creation of intricate leather goods. The Renaissance witnessed a flourishing of leather's popularity, particularly in cities like Florence, renowned for their exceptional craftsmanship. With the advent of the industrial revolution, leather production underwent a revolutionary transformation. Mechanization revolutionized the industry, boosting output and accessibility. Today, leather remains cherished for its durability, aesthetic appeal, and its enduring link to humanity's ancient craft traditions, a testament to its timeless significance in the annals of history.
                </p>
                
            </Col> */}
                <Col md={11} className="centeredcol carouselposition maxheight">
                    <Carousel className="">
                        <Carousel.Item>
                            <img
                                className="d-block w-100 imageSize"
                                src="images/DSC09355.jpg"
                                alt="" />
                            <Carousel.Caption>
                                <h3>New Card Wallets In Stock!</h3>
                                <p></p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100 imageSize"
                                src="images/DSC06141.jpg"
                                alt="" />
                            <Carousel.Caption>
                                <h3>Made to Measure Belts</h3>
                                <p></p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100 imageSize"
                                src="images/Catalog-10.jpg"
                                alt="" />
                            <Carousel.Caption>
                                <h3>Horween Shell Cordovan</h3>
                                <p></p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100 imageSize"
                                src="images/DSC06737.jpg"
                                alt="" />
                            <Carousel.Caption>
                                <h3>Personalized Projects</h3>
                                <p></p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100 imageSize"
                                src="images/DSC09387.jpg"
                                alt="" />

                            <Carousel.Caption>
                                <h3>Choose Your Colors </h3>
                                <p></p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </Col>
            </Row>


            <Row className="padded-top-bot carouselposition">
                <h1></h1>
                <h1 className="maxheight centeredcol">Brief History of Leather</h1>
                {/* <Col className=""> */}
                <p>
                    <br></br>
                    Leather's history is an ancient tale of human ingenuity and craftsmanship, spanning millennia. Originating in prehistoric times, early humans discovered the durability and versatility of animal hides, using them for clothing and tools. As civilizations like Egypt and Rome emerged, leatherworking techniques were refined, and it became a symbol of luxury and status. During the Middle Ages, guilds of skilled leatherworkers advanced tanning methods, leading to intricate leather goods. The Renaissance era witnessed a surge in leather's popularity, particularly in cities like Florence. With the industrial revolution, mechanized processes transformed production. Today, leather endures as a cherished material, celebrated for its durability and timeless aesthetic, preserving a deep connection to humanity's ancient craft traditions.                </p>

            </Row>

            <Row className="padded-top-bot carouselposition">
                <h1 className="maxheight centeredcol">Popular Products:</h1>
                <h1></h1>
                <h1></h1>
                <Col md={3} className="centeredcol">
                    <img src="images/Catalog-18.jpg" alt="Waterproof Daily Bag" />
                    <h3> Hermann Oak Daily Bag</h3>
                    <br></br>
                </Col>
                <Col md={3} className="centeredcol">
                    <img src="images/DSC06743.JPG" alt="shell-cordovan wallet" />
                    <h3> Japanese Shell Cordovan</h3>
                    <br></br>
                </Col>
                <Col md={3} className="centeredcol">
                    <img src="images/Catalog-08.jpg" alt="shell-cordovan wallet" />
                    <h3> Horween Shell Cordovan</h3>
                    <br></br>
                </Col>
                <Col md={3} className="centeredcol">
                    <img src="images/DSC06741.JPG" alt="Crocodile wallets" />
                    <h3> Burgundy Crocodile Wallet</h3>
                    <br></br>
                </Col>
            </Row>


            <Row className="padded-top-bot carouselposition">
                <h3> Vegetable-tanned leather </h3>
                <img
                    className="d-block w-100 imageSize"
                    src="images/vegtan.jpg"
                    alt="veg tan leather" />
                <p>
                    <br></br>
                    Vegetable-tanned leather, an ancient method of tanning, involves using natural plant-derived tannins to transform animal hides into a durable material. This eco-friendly process results in leather known for its ability to develop a rich patina over time, adding character and enhancing its strength. Highly valued for its rustic aesthetic and craftsmanship, products like belts, wallets, and bags are commonly made from vegetable-tanned leather. While initially stiffer than chemically-tanned leathers, it softens and molds with use, providing a comfortable and personalized fit. With its natural composition and timeless appeal, vegetable-tanned leather stands as a testament to traditional craftsmanship and sustainable practices in the world of leatherworking.
                </p>
                <ul>
                    <li>Full-Grain Leather</li>
                    <li>Top-Grain Leather</li>
                    <li>Natural Veg-Tan Leather</li>
                    <li>Bridle Leather</li>
                    <li>Latigo Leather</li>
                    <li>Harness Leather</li>
                    <li>Tooling Leather</li>
                </ul>
            </Row>

            <Row className="padded-top-bot carouselposition ">
                <h3> Chrome-tanned / Garment leather </h3>
                <img
                    className="d-block w-100 imageSize"
                    src="images/garment.jpg"
                    alt="chrome tan / garment leather" />
                <p>
                    <br></br>
                    Chrome tanned leather is a widely used type of leather produced through a process utilizing chromium salts. This method, introduced in the early 20th century, revolutionized leather production due to its speed and efficiency. Unlike traditional vegetable tanning, which can take several weeks, chrome tanning can be completed in a matter of hours. This results in a soft, supple leather that is highly resistant to water and requires minimal maintenance. Chrome tanned leather is popular for a wide range of applications, from footwear and handbags to upholstery and automotive interiors. However, it is worth noting that while it boasts excellent durability and versatility, it does not develop the same rich patina over time as vegetable-tanned leather. Additionally, the chromium salts used in the tanning process can have environmental implications if not properly managed, prompting efforts to develop more eco-friendly alternatives within the leather industry.
                </p>
                <ul>
                    <li>Full-Grain Chrome Leather</li>
                    <li>Top-Grain Chrome Leather</li>
                    <li>Nubuck Chrome Leather</li>
                    <li>Suede Chrome Leather</li>
                    <li>Oil-Tanned Leather</li>
                    <li>Embossed Chrome Leather</li>
                    <li>Patent Chrome Leather</li>
                </ul>
            </Row>
            <Row className="padded-top-bot carouselposition">
                <h3> Exotic leather </h3>

                <img
                    className="d-block w-100 imageSize"
                    src="images/exotic.jpg"
                    alt="exotic leather" />
                <p>
                    <br></br>
                    <br></br>
                    Exotic leather encompasses a diverse range of materials sourced from non-traditional animals, distinct from commonly used cowhide. This category includes skins from creatures like alligators, crocodiles, snakes, ostriches, and even more rare species like stingrays and sharks. Each type of exotic leather offers unique textures, patterns, and qualities, making them highly sought after for luxury goods and high-end fashion. The rarity and difficulty in obtaining these skins contribute to their exclusivity and higher price points. However, the exotic leather trade has raised ethical and conservation concerns, leading to regulations and sustainable farming practices to mitigate potential harm to wildlife populations. Despite these challenges, exotic leather remains a coveted choice for those seeking distinctive, premium-quality materials in the world of fashion and accessories.
                </p>
                <ul>
                    <li>Alligator Leather</li>
                    <li>Crocodile Leather</li>
                    <li>Python Leather</li>
                    <li>Ostrich Leather</li>
                    <li>Stingray Leather</li>
                    <li>Shark Leather</li>
                    <li>Lizard Leather</li>
                </ul>
            </Row>
        </>
    );
}

function DesktopView() {
    return (

        <>
            <Row className="padded-top-bot ">
                {/* <Col md={4} className="centeredcol carouselposition maxheight">
                <h1 className="maxheight centeredcol">Brief History of Leather</h1>
                    <p>
                        <br></br>
                        The history of leather spans millennia, beginning in prehistoric times when early humans recognized the resilience and adaptability of animal hides. These hides were utilized for essential needs such as clothing, shelter, and tools. As civilizations like Egypt and Rome emerged, they refined leatherworking techniques, employing it for garments, footwear, and even ceremonial artifacts. In Rome, leather became synonymous with luxury, adorning the elite as a symbol of status and prestige.
                    </p>
                    <p>
                        During the Middle Ages, skilled leatherworker guilds surfaced, leading to advancements in tanning processes and the creation of intricate leather goods. The Renaissance witnessed a flourishing of leather's popularity, particularly in cities like Florence, renowned for their exceptional craftsmanship. With the advent of the industrial revolution, leather production underwent a revolutionary transformation. Mechanization revolutionized the industry, boosting output and accessibility. Today, leather remains cherished for its durability, aesthetic appeal, and its enduring link to humanity's ancient craft traditions, a testament to its timeless significance in the annals of history.
                    </p>
                    
                </Col> */}
                <Col md={11} className="centeredcol carouselposition maxheight">
                    <Carousel className="">
                        <Carousel.Item>
                            <img
                                className="d-block w-100 imageSize"
                                src="images/DSC09355.jpg"
                                alt="" />
                            <Carousel.Caption>
                                <h3>New Card Wallets In Stock!</h3>
                                <p></p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100 imageSize"
                                src="images/DSC06141.jpg"
                                alt="" />
                            <Carousel.Caption>
                                <h3>Made to Measure Belts</h3>
                                <p></p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100 imageSize"
                                src="images/Catalog-10.jpg"
                                alt="" />
                            <Carousel.Caption>
                                <h3>Horween Shell Cordovan</h3>
                                <p></p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100 imageSize"
                                src="images/DSC06737.jpg"
                                alt="" />
                            <Carousel.Caption>
                                <h3>Personalized Projects</h3>
                                <p></p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100 imageSize"
                                src="images/DSC09387.jpg"
                                alt="" />

                            <Carousel.Caption>
                                <h3>Choose Your Colors </h3>
                                <p></p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </Col>
            </Row>


            <Row className="padded-top-bot carouselposition">
                <h1 className="maxheight centeredcol">Brief History of Leather</h1>
                {/* <Col className=""> */}
                <p>
                    <br></br>
                    The history of leather spans millennia, beginning in prehistoric times when early humans recognized the resilience and adaptability of animal hides. These hides were utilized for essential needs such as clothing, shelter, and tools. As civilizations like Egypt and Rome emerged, they refined leatherworking techniques, employing it for garments, footwear, and even ceremonial artifacts. In Rome, leather became synonymous with luxury, adorning the elite as a symbol of status and prestige.
                </p>
                {/* </Col>
                <Col className=""> */}
                <p>
                    <br></br>
                    During the Middle Ages, skilled leatherworker guilds surfaced, leading to advancements in tanning processes and the creation of intricate leather goods. The Renaissance witnessed a flourishing of leather's popularity, particularly in cities like Florence, renowned for their exceptional craftsmanship. With the advent of the industrial revolution, leather production underwent a revolutionary transformation. Mechanization revolutionized the industry, boosting output and accessibility. Today, leather remains cherished for its durability, aesthetic appeal, and its enduring link to humanity's ancient craft traditions, a testament to its timeless significance in the annals of history.
                </p>
                {/* </Col> */}

            </Row>

            <Row className="padded-top-bot carouselposition">
                <h1 className="maxheight centeredcol">Popular Products:</h1>
                <h1></h1>
                <h1></h1>
                <Col md={3} className="centeredcol">
                    <img src="images/Catalog-18.jpg" alt="Waterproof Daily Bag" />
                    <h3> Hermann Oak Daily Bag</h3>
                    <br></br>
                </Col>
                <Col md={3} className="centeredcol">
                    <img src="images/DSC06743.JPG" alt="shell-cordovan wallet" />
                    <h3> Japanese Shell Cordovan</h3>
                    <br></br>
                </Col>
                <Col md={3} className="centeredcol">
                    <img src="images/Catalog-08.jpg" alt="shell-cordovan wallet" />
                    <h3> Horween Shell Cordovan</h3>
                    <br></br>
                </Col>
                <Col md={3} className="centeredcol">
                    <img src="images/DSC06741.JPG" alt="Crocodile wallets" />
                    <h3> Burgundy Crocodile Wallet</h3>
                    <br></br>
                </Col>
            </Row>
            
            <Row className="padded-top-bot carouselposition">
                <h1 className="maxheight centeredcol">Types of Leather We Work With</h1>
                <h1></h1>
                <Col md={4} className="centeredcol">
                    <img src="images/vegtan.jpg" alt="gen leather" />
                </Col>
                <Col md={4} className="centeredcol">
                    <img src="images/garment.jpg" alt="gen leather" />
                </Col>
                <Col md={4} className="centeredcol">
                    <img src="images/exotic.jpg" alt="gen leather" />
                </Col>
            </Row>

            <Row className="padded-top-bot carouselposition">
                <br></br>
                <Col className="">
                    <br></br>
                    <h3> Vegetable-tanned leather </h3>
                    <p>
                        <br></br>
                        Vegetable-tanned leather, an ancient method of tanning, involves using natural plant-derived tannins to transform animal hides into a durable material. This eco-friendly process results in leather known for its ability to develop a rich patina over time, adding character and enhancing its strength. Highly valued for its rustic aesthetic and craftsmanship, products like belts, wallets, and bags are commonly made from vegetable-tanned leather. While initially stiffer than chemically-tanned leathers, it softens and molds with use, providing a comfortable and personalized fit. With its natural composition and timeless appeal, vegetable-tanned leather stands as a testament to traditional craftsmanship and sustainable practices in the world of leatherworking.
                    </p>
                    <ul>
                        <li>Full-Grain Leather</li>
                        <li>Top-Grain Leather</li>
                        <li>Natural Veg-Tan Leather</li>
                        <li>Bridle Leather</li>
                        <li>Latigo Leather</li>
                        <li>Harness Leather</li>
                        <li>Tooling Leather</li>
                    </ul>
                </Col>
                <Col className="">
                    <br></br>
                    <h3> Chrome-tanned / Garment leather </h3>
                    <p>
                        <br></br>
                        Chrome tanned leather is a widely used type of leather produced through a process utilizing chromium salts. This method, introduced in the early 20th century, revolutionized leather production due to its speed and efficiency. Unlike traditional vegetable tanning, which can take several weeks, chrome tanning can be completed in a matter of hours. This results in a soft, supple leather that is highly resistant to water and requires minimal maintenance. Chrome tanned leather is popular for a wide range of applications, from footwear and handbags to upholstery and automotive interiors. However, it is worth noting that while it boasts excellent durability and versatility, it does not develop the same rich patina over time as vegetable-tanned leather. Additionally, the chromium salts used in the tanning process can have environmental implications if not properly managed, prompting efforts to develop more eco-friendly alternatives within the leather industry.
                    </p>
                    <ul>
                        <li>Full-Grain Chrome Leather</li>
                        <li>Top-Grain Chrome Leather</li>
                        <li>Nubuck Chrome Leather</li>
                        <li>Suede Chrome Leather</li>
                        <li>Oil-Tanned Leather</li>
                        <li>Embossed Chrome Leather</li>
                        <li>Patent Chrome Leather</li>
                    </ul>
                </Col>
                <Col className="">
                    <br></br>
                    <h3> Exotic leather </h3>
                    <p>
                        <br></br>
                        <br></br>
                        Exotic leather encompasses a diverse range of materials sourced from non-traditional animals, distinct from commonly used cowhide. This category includes skins from creatures like alligators, crocodiles, snakes, ostriches, and even more rare species like stingrays and sharks. Each type of exotic leather offers unique textures, patterns, and qualities, making them highly sought after for luxury goods and high-end fashion. The rarity and difficulty in obtaining these skins contribute to their exclusivity and higher price points. However, the exotic leather trade has raised ethical and conservation concerns, leading to regulations and sustainable farming practices to mitigate potential harm to wildlife populations. Despite these challenges, exotic leather remains a coveted choice for those seeking distinctive, premium-quality materials in the world of fashion and accessories.
                    </p>
                    <ul>
                        <li>Alligator Leather</li>
                        <li>Crocodile Leather</li>
                        <li>Python Leather</li>
                        <li>Ostrich Leather</li>
                        <li>Stingray Leather</li>
                        <li>Shark Leather</li>
                        <li>Lizard Leather</li>
                    </ul>
                </Col>

            </Row>
        </>
    );
}

// export default App;