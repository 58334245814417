import React, { Component } from "react";
import Home from "./Page/Home.tsx";
import "./App.css"
import { Routes, Route, Link, HashRouter, BrowserRouter } from 'react-router-dom';
import About from './Page/About.tsx';
import Contact from './Page/Contact.tsx';
import Catalog from './Page/Catalog.tsx';
import Navigation from "./Components/Navigation";
import NewArrivals from "./Page/newArrivals.tsx";
import Footer from "./Components/Footer";




class App extends Component {
  render() {
    return (
      <div>
        <div>
          <Navigation />
        </div>
        <BrowserRouter basename="/">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/new_arrivals" element={<NewArrivals />} />
            <Route path="/catalog" element={<Catalog />} />
            <Route path="/commissions" element={<Contact />} />
            <Route path="/about_me" element={<About />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </div>
    );
  }
};

export default App;