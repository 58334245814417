import React from 'react';
import { Row, Col } from "react-bootstrap";


export default function About() {
  return (
    <> 
      <Row className="padded-top-bot centered carouselposition">
        <Col md={11} className="centeredcol">
        <h3> Progress since the first creation!</h3>
        <br></br>
          <img src="images/DSC06659.JPG" alt="Progression" />          
          <br></br>
        </Col>
      </Row>
      
      <Row className="padded-top-bot centered carouselposition">
        <Col md={11} className="centeredcol">
        <h3> How'd we get here? </h3>
        <br></br>
          <p>
            Embarking on the journey of crafting leather products has been a remarkable adventure for me, a young man with a passion for craftsmanship and a dream of creating something meaningful from scratch. Starting with just a handful of basic tools and a small corner of my room, I delved into the world of leatherworking with wide-eyed determination. Each stitch and cut became a lesson, and every project was a chance to refine my skills and understand the unique character of leather. From those early, tentative attempts, I've witnessed my work evolve, gradually transitioning from hesitant beginnings to creations I'm truly proud of. It's a process that's taught me patience, instilled a deep respect for the material, and kindled an unbreakable bond with the art of leather. Today, as I look back at my humble start, I'm reminded that every masterpiece begins with a single stitch and a passionate heart. This journey has not only shaped my skill, but it's become a canvas for my aspirations, a testament to the transformative power of youthful determination and the fulfillment that arises from a creative calling.
          </p>
        </Col>
      </Row>
    </>
  );
}

