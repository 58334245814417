import React from "react";
import { ReactComponent as FacebookIcon } from '../assets/facebook.svg';
import { ReactComponent as InstagramIcon } from '../assets/instagram.svg';
import { ReactComponent as ChatIcon } from '../assets/chat-dots-fill.svg';
import { ReactComponent as UpArrow } from '../assets/up-arrow.svg';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  
  return (
      <footer className="footer">
        <div className="">
        <button onClick={scrollToTop} className="back-to-top">
        < UpArrow className="uparrow"/>
        </button>
        </div>
        
        <div className="social-icons">
          <a href="https://www.facebook.com"><FacebookIcon /></a>
          <a href="https://www.instagram.com"><InstagramIcon /></a>
          <a href=""><ChatIcon /></a>
        </div>
    </footer>
  );
};
export default Footer;

// <div>
//         <Row>
//              <Navbar>
//           <Container fluid>
//             <Navbar.Brand>© 2023 Nature-Leather Co</Navbar.Brand>
//             <Navbar.Toggle aria-controls={"offcanvasNavbar-footer"} />
//             <Navbar.Offcanvas
//               id={"offcanvasNavbar-footer"}
//               aria-labelledby={"offcanvasNavbarLabel-footer"}
//               placement="end"
//             >
//               <Offcanvas.Header closeButton>
//               </Offcanvas.Header>
//               <Offcanvas.Body>
//                 <Nav className="justify-content-end flex-grow-1">
//                   <Nav.Link> <img src="/images/facebook.svg" alt="facebook" width="auto" height="auto"></img></Nav.Link>
//                   <Nav.Link><img src="/images/instagram.svg" alt="instagram" width="auto" height="auto"></img></Nav.Link>
//                   <Nav.Link><img src="/images/chat-dots-fill.svg" alt="chat" width="auto" height="auto"></img></Nav.Link>
//                 </Nav>
//               </Offcanvas.Body>
//             </Navbar.Offcanvas>
//           </Container>
//         </Navbar>
//         </Row>
//         </div>