import { Container, Row, Col, Card } from "react-bootstrap";
import React, { useEffect } from 'react';


const NewArrivalGal = () => {
    useEffect(() => {
        const disableContextMenu = (event) => {
            event.preventDefault();
        }

        document.addEventListener('contextmenu', disableContextMenu);

        return () => {
            document.removeEventListener('contextmenu', disableContextMenu);
        }
    }, []);

    return (
        <Container >
            <Row className="padded-top-bot carouselposition">
                <Col md={4}>
                    <Card className="cardstyle cardstyle bg-dark text-white">
                        <Card.Img src="images/shellWallet.jpg" alt="Shell Wallet" />
                        <Card.Body>
                            <Card.Text className="galleryfont">Horween Shell Cordovan Wallet</Card.Text>
                            {/* <Card.Text> */}
                            {/* Custom Designs */}
                            {/* </Card.Text> */}
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card border="light" key="dark" bg="dark" text="white" className="cardstyle mb-2">
                        <Card.Img variant="top" src="images/Catalog-18.jpg" alt="Hermann Oak Waterproof Bag" />
                        <Card.Body>
                            <Card.Text className="galleryfont">Hermann Oak Waterproof Bag</Card.Text>
                            {/* <Card.Text> */}
                            {/* Made to Measure */}
                            {/* </Card.Text> */}
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="cardstyle bg-dark text-white">
                        <Card.Img src="images/DSC09355.jpg" alt="Catalina Card Wallet" />
                        <Card.Body>
                            <Card.Text className="galleryfont">Catalina Card Wallet</Card.Text>
                            {/* <Card.Text> */}
                            {/* Custom Designs */}
                            {/* </Card.Text> */}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="padded-top-bot carouselposition">
                <Col md={4}>
                    <Card className="cardstyle bg-dark text-white">
                        <Card.Img src="images/DSC06139.JPG" alt="Custom Belts" />
                        <Card.Body>
                            <Card.Text className="galleryfont">Made-to-Measure Feather Belt</Card.Text>
                            {/* <Card.Text> */}
                            {/* Made to Measure */}
                            {/* </Card.Text> */}
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="cardstyle bg-dark text-white">
                        <Card.Img src="images/DSC06743.JPG" alt="Shell Cordovan Wallets" />
                        <Card.Body>
                            <Card.Text className="galleryfont">Shell Cordovan Minimalist Wallet</Card.Text>
                            {/* <Card.Text> */}
                            {/* Custom Designs */}
                            {/* </Card.Text> */}
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card border="light" key="dark" bg="dark" text="white" className="cardstyle mb-2">
                        <Card.Img variant="top" src="images/DSC09387.jpg" alt="Wristwatch Travel Pouch" />
                        <Card.Body>
                            <Card.Text className="galleryfont">Wristwatch Travel Pouch</Card.Text>
                            {/* <Card.Text> */}
                            {/* Made to Measure */}
                            {/* </Card.Text> */}
                        </Card.Body>
                    </Card>
                </Col>
                </Row>
                <Row className="padded-top-bot carouselposition">
                    <Col md={4}>
                        <Card className="cardstyle bg-dark text-white">
                            <Card.Img src="images/DSC06741.JPG" alt="Exotic Leather Wallets" />
                            <Card.Body>
                                <Card.Text className="galleryfont">Alligator Leather Pocket Wallets</Card.Text>
                                {/* <Card.Text> */}
                                {/* Made to Measure */}
                                {/* </Card.Text> */}
                            </Card.Body>
                        </Card>
                    </Col>


                    <Col md={4}>
                        <Card className="cardstyle bg-dark text-white">
                            <Card.Img src="images/bagveg.JPG" alt="Vegetable Tan Bag" />
                            <Card.Body>
                                <Card.Text className="galleryfont">Vegetable Tan Tote Bag</Card.Text>
                                {/* <Card.Text> */}
                                {/* Made to Measure */}
                                {/* </Card.Text> */}
                            </Card.Body>
                        </Card>
                    </Col>
                <Col md={4}>
                    <Card className="cardstyle bg-dark text-white">
                        <Card.Img src="images/DSC06737.jpg" alt="Shell Cordovan Passport Wallet" />
                        <Card.Body>
                            <Card.Text className="galleryfont">Shell Cordovan Passport Wallet</Card.Text>
                            {/* <Card.Text> */}
                            {/* Made to Measure */}
                            {/* </Card.Text> */}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="padded-top-bot carouselposition">
                <Col md={4}>
                    <Card className="cardstyle bg-dark text-white">
                        <Card.Img src="images/DSC06740.JPG" alt="Satchel" />
                        <Card.Body>
                            <Card.Text className="galleryfont">Hermann Oak Satchel</Card.Text>
                            {/* <Card.Text> */}
                            {/* Custom Designs */}
                            {/* </Card.Text> */}
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="cardstyle bg-dark text-white">
                        <Card.Img src="images/DSC09568.JPG" alt="Crazy Horse Backpack" />
                        <Card.Body>
                            <Card.Text className="galleryfont">Crazy Horse Backpack</Card.Text>
                            {/* <Card.Text> */}
                            {/* Made to Measure */}
                            {/* </Card.Text> */}
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="cardstyle bg-dark text-white">
                        <Card.Img src="images/DSC06373.JPG" alt="Other Products" />
                        <Card.Body>
                            <Card.Text className="galleryfont">Vegetable Tan Baseball</Card.Text>
                            {/* <Card.Text> */}
                            {/* Placeholder */}
                            {/* </Card.Text> */}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};
export default NewArrivalGal;

